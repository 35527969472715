<template>
  <v-autocomplete
    multiple
    :items="process"
    :no-data-text="$t('general.fields.noDataAvailable')"
    :clearable="true"
    v-model="filters.processes"
    item-color="primary"
    item-text="proccess_name"
    item-value="id"
    dense
    flat
    outlined
    color="primary"
    :menu-props="{ closeOnContentClick: false }"
  >
    <template v-slot:prepend-item>
      <v-list-item @click="() => toggleSelection()">
        <v-list-item-action>
          <v-icon color="primary">{{ computeSelectionIcon() }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          {{ filters.processes.length === process.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:label>
      {{ $t('widgets.dialogs.filters.process') }}
    </template>
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index === 0" small>
        <span>{{ item.proccess_name | truncate(18) }}</span>
      </v-chip>
      <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
      >(+{{ process.length - 1 }}
                      {{ $t('general.titles.others') }})</span
      >
    </template>
    <template v-slot:item="{ item, attrs }">
      <v-checkbox
        :input-value="attrs.inputValue"
        color="primary"
        class="pt-2 pb-2 mt-0"
        :hide-details="true"
      >
        <template v-slot:label>
          <read-more
            :classes="'mb-0'"
            :text="item.proccess_name"
            :max-chars="160"
            :font-size="14"
          />
        </template>
      </v-checkbox>
    </template>
  </v-autocomplete>
</template>

<script>

import ReadMore from "@/components/ReadMoreComponent.vue";
import {mapGetters} from "vuex";

export default {
  name: "ProcessPicker",
  components: { ReadMore },
  computed: {
    ...mapGetters({
      process: 'authority/authoritiesProcess'
    }),
    filters: {
      get() {
        return this.$store.getters['dashboard/GET_FILTERS'];
      },
      set(val) {
        this.$store.commit('dashboard/SET_FILTERS', val);
      }
    },
  },
  methods: {
    async toggleSelection() {
      let allItems = [];
      let selectedItems = [];

      allItems = this.process;
      selectedItems = this.filters.processes;

      if (selectedItems.length < allItems.length) {
        this.filters.processes = allItems.map(item => item.id);
      } else {
        this.filters.processes = [];
      }
    },

    computeSelectionIcon() {
      let allItems = [];
      let selectedItems = [];

      allItems = this.process;
      selectedItems = this.filters.processes;

      if (selectedItems.length === 0) {
        return 'mdi-checkbox-blank-outline';
      } else if (selectedItems.length === allItems.length) {
        return 'mdi-checkbox-marked';
      } else {
        return 'mdi-minus-box';
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div v-if="!loading" class="fill-height">
    <div id="chart" v-if="isPresent" class="fill-height">
      <v-card elevation="0" class="ma-0 pa-0 fill-height overflow-hidden">
        <v-overlay opacity="0.5" v-if="isEmpty" absolute color="white" style="height: 100%">
          <div class="d-flex justify-center align-center fill-height">
            <v-chip small disabled light>
              <v-icon small>mdi-alert-outline</v-icon>
              <p style="font-size: 12px; font-weight: normal" class="ma-0 pa-0 mx-4">{{ $t('general.ui.not_data_found') }}</p>
            </v-chip>
          </div>
        </v-overlay>
        <apexchart
          ref="chart"
          v-if="ready"
          :options="chartOptions"
          :series="chartSeries"
          height="100%"
        ></apexchart>
      </v-card>
    </div>
    <div v-else class="d-flex justify-center align-center fill-height">
      <v-chip small not-apply-styles>
        <v-icon small>mdi-alert-outline</v-icon>
        <p style="font-size: 12px; font-weight: normal" class="ma-0 pa-0 mx-4">{{ $t('general.ui.not_data_found') }}</p>
      </v-chip>
    </div>
  </div>
  <div v-else class="d-flex justify-center align-center fill-height">
    <v-chip small not-apply-styles>
      <v-progress-circular indeterminate v-if="loading" color="neuter" size="14" width="3" />
      <p style="font-size: 12px; font-weight: normal" class="ma-0 pa-0 mx-4">{{ $t('widgets.loadData') }}</p>
    </v-chip>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import VueApexCharts from 'vue-apexcharts'
import LanguajeService from "@/services/LanguajeService";
import {mapGetters} from "vuex";
import { FilterWidgetMixin } from "@/module/dashboard/mixins/filter-widget-mixin";

export default {
  name: "RenderValues",
  mixins: [FilterWidgetMixin],
  components: {
    apexchart: VueApexCharts
  },
  props: {
    initialized: {
      type: Boolean,
      default: false
    },
    tColor: {
      type: String,
      default: '#232323'
    },
    widgetId: {
      type: String,
      required: true
    },
    ready: {
      type: Boolean,
      default: false
    },
    chartOptions: {
      type: Object,
      required: true
    },
    chartSeries: {
      type: Array,
      required: false,
      default: () => []
    },
    applicableFilters: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data() {
    return {
      isPresent: false,
      value: 0,
      loading: false,
      isEmpty: false
    };
  },
  // async created() {
  //   await this.fetchData(true);
  // },
  mounted() {
    this.$root.$on('apply:dashboard-filters', (checkOnCreate = false) => {
      this.fetchData(checkOnCreate);
    })
  },
  computed: {
    ...mapGetters({
      layout: 'dashboard/LAYOUT'
    }),
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    }
  },
  methods: {
    checkIsEmpty(array) {
      const sum = array.reduce((a, b) => {
        return a + b
      }, 0);

      return sum === 0;
    },
    refreshChart() {
      this.$emit('on:ready', true);

      if (this.$refs.chart) {
        this.$refs.chart.refresh();
      }
    },
    export(fileName) {
      const chart = this.$refs.chart;

      chart.dataURI().then(({ imgURI }) => {
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'mm',
          format: 'a4'
        });

        const pageWidth = 297; // A4 tamaño en mm
        const pageHeight = 210; // A4 altura en mm

        const img = new Image();
        img.src = imgURI;
        img.onload = () => {
          const imgWidth = img.width;
          const imgHeight = img.height;

          // Calcular la relación de aspecto para mantener la proporción de la imagen
          const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

          // Calcular las dimensiones finales de la imagen
          const finalImgWidth = imgWidth * ratio;
          const finalImgHeight = imgHeight * ratio;

          // Calcular la posición para centrar la imagen
          const xPosition = (pageWidth - finalImgWidth) / 2;
          const yPosition = (pageHeight - finalImgHeight) / 2;

          pdf.addImage(imgURI, 'PNG', xPosition, yPosition, finalImgWidth, finalImgHeight);

          pdf.save(`${fileName}.pdf`);
        };
      });
    },
    async fetchData(checkOnCreated) {
      this.$emit('on:ready', false);

      if (!this.isEditingEnabled) {
        if (checkOnCreated || this.initialized) {
          this.loading = true;
          const filters = this.filterObjectByKeys(this.filters, this.applicableFilters);
          await this.$store.dispatch('dashboard/fetchWidgetConfig', [this.widgetId, filters])
            .then(res => {
              if (res.reload) {
                this.$root.$emit('reload:layout', { reload: true, layoutId: this.layout.id });
              }

              if (res) {
                if (!(res.keys.length > 0 && res.values.length > 0)) {
                  this.isPresent = false;
                  this.$emit('on:ready', false);
                } else {
                  this.$emit('update:values', {
                    xAxis: res.keys,
                    yAxis: res.values,
                    format: {
                      afterPoint: res.format.afterPoint,
                      region: res.format.region
                    }
                  });

                  this.isEmpty = this.checkIsEmpty(res.values);

                  this.isPresent = true;
                  this.$emit('on:ready', true);
                }
              } else {
                this.isPresent = false;
                this.$emit('on:ready', false);
              }
            })
            .catch(err => {
              console.log(err)
              this.isPresent = false;
              this.$emit('on:ready', false);
            })
            .finally(() =>  this.loading = false)
        } else {
          this.loading = false;
        }
      } else {
        this.isPresent = false
        this.loading = false
      }
    },
    translate(key, uppercase) {
      return LanguajeService.getKey3(key)
    },
  },

  destroyed() {
    this.$root.$off('apply:dashboard-filters')
  }
}
</script>

<style scoped>
.v-chip {
  border-radius: .3em;
}
</style>
<template>
  <v-autocomplete
    multiple
    :items="stages"
    :no-data-text="$t('general.fields.noDataAvailable')"
    :clearable="true"
    v-model="filters.stages"
    item-color="primary"
    item-text="storage_type_name"
    item-value="storage_device_type_id"
    dense
    flat
    outlined
    color="primary"
    :menu-props="{ closeOnContentClick: false }"
  >
    <template v-slot:prepend-item>
      <v-list-item @click="() => toggleSelection()">
        <v-list-item-action>
          <v-icon color="primary">{{ computeSelectionIcon() }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          {{ filters.stages.length === stages.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:label>
      {{ $t('widgets.filters.stages') }}
    </template>
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index === 0" small>
        <span>{{ item.storage_type_name | truncate(18) }}</span>
      </v-chip>
      <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
      >(+{{ filters.stages.length - 1 }}
                      {{ $t('general.titles.others') }})</span
      >
    </template>
    <template v-slot:item="{ item, attrs }">
      <v-checkbox
        :input-value="attrs.inputValue"
        color="primary"
        class="pt-2 pb-2 mt-0"
        :hide-details="true"
      >
        <template v-slot:label>
          <read-more
            :classes="'mb-0'"
            :text="item.storage_type_name"
            :max-chars="160"
            :font-size="14"
          />
        </template>
      </v-checkbox>
    </template>
  </v-autocomplete>
</template>

<script>
import ReadMore from "@/components/ReadMoreComponent.vue";
import sortList from "@/services/OrderListService"

export default {
  name: "StagesPicker",
  components: { ReadMore },
  computed: {
    stages() {
      return sortList.orderListByUppercase(
        this.$store.getters['storage_type/storageDeviceTypesActiveByCompany'],
        'storage_type_name'
      )
    },
    filters: {
      get() {
        return this.$store.getters['dashboard/GET_FILTERS'];
      },
      set(val) {
        this.$store.commit('dashboard/SET_FILTERS', val);
      }
    },
  },
  methods: {
    async toggleSelection() {
      let allItems = [];
      let selectedItems = [];

      allItems = this.stages;
      selectedItems = this.filters.stages;

      if (selectedItems.length < allItems.length) {
        this.filters.stages = allItems.map(item => item.storage_device_type_id);
      } else {
        this.filters.stages = [];
      }
    },

    computeSelectionIcon() {
      let allItems = [];
      let selectedItems = [];

      allItems = this.stages;
      selectedItems = this.filters.stages;

      if (selectedItems.length === 0) {
        return 'mdi-checkbox-blank-outline';
      } else if (selectedItems.length === allItems.length) {
        return 'mdi-checkbox-marked';
      } else {
        return 'mdi-minus-box';
      }
    },
  }
}
</script>

<style scoped>

</style>
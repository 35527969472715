<template>
  <div v-if="!loading" class="fill-height align-content-center align-center d-flex full-width justify-center">
    <div v-if="isPresent" :style="`color: ${tColor}`" class="ma-0 pa-1 fill-height full-width">
      <map-box
        v-show="!loading"
        :displayDrawControlsDefault="false"
        :drawControl="true"
        :mapConfiguration="mapConfig"
        :oneLocation="true"
        :scaleControl="true"
        :actionStyle="true"
        :actionColor="false"
        ref="mapMonitor"
        :geolocateControl="true"
        :fullScreenControl="true"
        v-on:loadComplete="loadMapComplete"
        mapHeight="auto"
        :confStyle="'satellite-v9'"
        v-on:styleLoad="setStyle"
        v-on:filterDash="filterMap"
        :stages="stages"
        :layers="layers"
        :is-rounded="true"
      />
      <div v-if="loading" class="d-flex justify-center align-content-center align-center fill-height full-width">
        <v-chip small not-apply-styles>
          <v-progress-circular indeterminate color="grey darken-1" size="14" width="3" />
          <p style="font-size: 12px; font-weight: normal" class="ma-0 pa-0 mx-4">{{ $t('widgets.loadData') }}</p>
        </v-chip>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-content-center align-center fill-height full-width">
      <v-chip small not-apply-styles>
        <v-icon small>mdi-alert-outline</v-icon>
        <p style="font-size: 12px; font-weight: normal" class="ma-0 pa-0 mx-4">{{ $t('general.ui.not_data_found') }}</p>
      </v-chip>
    </div>
  </div>
  <div v-else class="d-flex justify-center align-content-center align-center fill-height full-width">
    <v-chip small not-apply-styles>
      <v-progress-circular indeterminate v-if="loading" color="neuter" size="14" width="3" />
      <p style="font-size: 12px; font-weight: normal" class="ma-0 pa-0 mx-4">{{ $t('widgets.loadData') }}</p>
    </v-chip>
  </div>
</template>

<script>
import _ from "lodash"
import * as turf from "@turf/turf"
import LanguageService from "@/services/LanguajeService";
import MapService from "@/services/MapService"
import { convertLatitude, convertLongitude, isValidLatLong } from "../../../../../../../utils/convert-to-DMS-format"
import { FilterWidgetMixin } from "@/module/dashboard/mixins/filter-widget-mixin";
import MapBox from "@/components/vueMapbox/MapBox.vue"
import { mapGetters } from "vuex"

export default {
  name: "RenderValues",
  components: { MapBox },
  mixins: [FilterWidgetMixin],
  props: {
    initialized: {
      type: Boolean,
      default: false
    },
    tColor: {
      type: String,
      default: '#232323'
    },
    widgetId: {
      type: String,
      required: true
    },
    layers: {
      type: Array,
      required: false,
      default: () => [
        { id: "l_locations_clusters", checked: true },
        { id: "l_locations_clusters_count", checked: true },
        { id: "l_locations_names", checked: true },

        { id: "l_locations_center_points", checked: false },
        { id: "l_locations_center_points_icon", checked: true },
        { id: "l_locations_clusters_points", checked: false },
        { id: "l_locations_clusters_points_icon", checked: true },
        { id: "l_locations_up_zoom", checked: true },
        { id: "l_locations_down_zoom", checked: false },

        { id: "l_storages_clusters", checked: true },
        { id: "l_storages_clusters_count", checked: true },
        { id: "l_storages_names", checked: true },
        { id: "l_storages_up_zoom", checked: true },
        { id: "l_storages_down_zoom", checked: false },
        { id: "l_storages_center_points", checked: false },
        { id: "l_storages_center_points_icon", checked: true },
        { id: "l_storages_clusters_points", checked: false },
        { id: "l_storages_clusters_points_icon", checked: true },
      ]
    },
    applicableFilters: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data() {
    return {
      isPresent: false,
      value: 0,
      loading: false,
      unit: null,
      afterPoint: null,
      region: null,
      loadingMap: true,
      stages: [],
      storages: [],
      locations: [],
      isReady: false,
    };
  },
  // async created() {
  //   await this.fetchData(true);
  // },
  mounted() {
    this.$root.$on('apply:dashboard-filters', (checkOnCreate = false) => {
      this.fetchData(checkOnCreate);
    })
    this.$root.$on('on:recalculate', () => {
      this.recalculating = true;
      this.filterObjectByKeys(this.filters, this.applicableFilters);
    })
  },
  computed: {
    ...mapGetters({
      storageDeviceTypesByCompany:
        "storage_type/storageDeviceTypesActiveByCompany",
    }),
    mapConfig: {
      get() {
        return this.$store.getters["general/mapConfig"]
      },
      set(val) {
        this.$store.commit("general/SET_MAP_CONFIG", val)
      },
    },
    config: {
      get() {
        return this.$store.getters['general/config']
      },
      set(val) {
        this.$store.commit('general/SET_CONFIG', val)
      }
    },
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    }
  },
  methods: {
    async fetchData(checkOnCreated) {
      if (!this.isEditingEnabled) {
        if (checkOnCreated || this.initialized) {
          this.loading = true;
          const profile = JSON.parse(localStorage.getItem('profile'));
          console.log(`filters-${profile.company_id}-${this.layout.id}`, this.filters);
          const filters = this.filterObjectByKeys(this.filters, this.applicableFilters);
          await this.$store.dispatch('dashboard/fetchWidgetConfig', [this.widgetId, filters])
            .then(res => {
              if (res.reload) {
                this.$root.$emit('reload:layout', { reload: true, layoutId: this.layout.id });
              }

              console.log(res.primaryLocations)
              console.log(res.secondaryLocations)
              this.locations = res.primaryLocations;
              this.storages = res.secondaryLocations;

              this.$emit('update:value', this.value);
              this.isPresent = true;
            })
            .catch(err => {
              console.log(err)
              this.isPresent = false;
            })
            .finally(() =>  this.loading = false)
        } else {
          this.loading = false;
        }
      } else {
        this.isPresent = false
        this.loading = false
      }
    },
    translate(key, uppercase) {
      return LanguageService.getKey3(key)
    },
    async loadMapComplete() {
      console.log("mapLoadComplete")
      // this.$refs.mapMonitor.map.resize();
      await this.reload()
      this.$refs.mapMonitor.map.resize();

      // Force update map layers for center point zoom
      setTimeout(async () => {
        if (this.$refs.mapMonitor) {
          const featureLocations = await this.getDataLocations()
          const featureStorages = await this.getDataStorages()
          let toZoom = null;

          if(featureLocations.allFeatures.features.length <= 0 ){
            toZoom = featureStorages.allFeatures
          } else {
            toZoom = featureLocations.allFeatures
          }

          this.$refs.mapMonitor.map.setMaxZoom(8)
          this.$refs.mapMonitor.zoomToFitBounds(toZoom, 100)
          this.$refs.mapMonitor.map.setMaxZoom(20)
        }
      }, 1000)
    },
    setStyle() {
      this.reload()
    },
    async filterMap(filterStages) {
      this.stages = filterStages
      // Get Data and config paint layer locations
      const featureLocations = await this.getDataLocations()
      // Get Data and config paint layer storages
      const featureStorages = await this.getDataStorages()

      this.updateSources(featureLocations, featureStorages)

    },
    async reload() {
      this.stages = Object.assign({}, this.storageDeviceTypesByCompany)
      await this.addLocationOnMap()
      this.loadingMap = false
    },
    async addLocationOnMap() {
      // Delete map config
      this.$refs.mapMonitor.deleteMapConfig()

      // Get Data and config paint layer storages
      const featureStorages = await this.getDataStorages()
      console.log('featureStorages', featureStorages)
      // Get Data and config paint layer locations
      const featureLocations = await this.getDataLocations()
      console.log('featureLocations', featureLocations)

      // Set config sources and layers
      this.$refs.mapMonitor.setMapConfig()

      this.updateSources(featureLocations, featureStorages)

      this.addEventsMap()
    },
    updateSources(featureLocations, featureStorages){
      let toZoom = null;

      if(featureLocations.allFeatures.features.length <= 0 ){
        console.log('only storages')
        toZoom = featureStorages.allFeatures
        _.map(this.mapConfig.layers, (l, i) => {
          if((l.source === 's_storages_clusters' || l.source === 's_storages') && l.minzoom === 6){
            l.minzoom = 2
          }
        })
      } else {
        toZoom = featureLocations.allFeatures
      }

      if (featureLocations){
        this.$refs.mapMonitor.updateSource(
          "s_locations_clusters",
          featureLocations.allCenterPointsFeature
        )
        this.$refs.mapMonitor.updateSource(
          "s_locations",
          featureLocations.allFeatures
        )
      }
      if (featureStorages){
        this.$refs.mapMonitor.updateSource(
          "s_storages_clusters",
          featureStorages.allCenterPointsFeature
        )
        this.$refs.mapMonitor.updateSource(
          "s_storages",
          featureStorages.allFeatures
        )
      }

      this.$refs.mapMonitor.map.setMaxZoom(8)
      this.$refs.mapMonitor.zoomToFitBounds(toZoom, 100)
      this.$refs.mapMonitor.map.setMaxZoom(20)
    },
    addEventsMap() {
      this.$refs.mapMonitor.onMouseClickClusterZoomLayer(
        "l_locations_clusters","s_locations_clusters"
      );
      this.$refs.mapMonitor.onMouseClickClusterZoomLayer(
        "l_storages_clusters","s_storages_clusters"
      )


      this.$refs.mapMonitor.onMouseHoverPopUp(
        [
          "l_storages_clusters_points_icon",
          "l_storages_clusters_points",
          "l_storages_center_points_icon",
          "l_storages_center_points",
          "l_locations_clusters_points_icon",
          "l_locations_clusters_points",
          "l_locations_center_points_icon",
          "l_locations_center_points"
        ],
        "pointer","layers"
      )
      this.$refs.mapMonitor.onMouseLeavePopUp(
        [
          "l_storages_clusters_points_icon",
          "l_storages_clusters_points",
          "l_storages_center_points_icon",
          "l_storages_center_points",
          "l_locations_clusters_points_icon",
          "l_locations_clusters_points",
          "l_locations_center_points_icon",
          "l_locations_center_points"
        ]
      )

    },
    checkIfExist(target, continueTrue, separator) {
      target = target ? target : ''
      continueTrue = continueTrue && target !== '' ? separator : ''
      return target + continueTrue
    },
    async getDataStorages() {
      let storageFeaturesBySDT = {
        type: "FeatureCollection",
        features: [],
      };
      let allCenterPointsFeature = {
        type: 'FeatureCollection',
        features: []
      };
      let location = null;
      let locationJson = null;
      const mergeFormat = await MapService.mergeLocationsFormats(this.storages);

      for (const locationItem of mergeFormat) {
        const sdt = _.find(this.stages, {
          storage_device_type_id: parseInt(locationItem.storageDeviceType.id),
          checked: true,
        });
        if (
          this.IsJsonString(locationItem.location) &&
          locationItem.location &&
          locationItem.location !== "null" &&
          sdt
        ) {
          locationJson = JSON.parse(locationItem.location);
          if (
            locationJson &&
            locationJson.features &&
            locationJson.features.length > 0
          ) {
            location = locationJson.features[0];
            if (!location.properties) {
              location.properties = {}
            }
            location.properties.id = locationItem.id;
            location.properties.storageName = locationItem.name;
            location.properties.gln = locationItem.gln;
            location.properties.locationName = locationItem.name;
            location.properties.primaryName = locationItem.primaryLocation.name;
            location.properties.area = locationItem.area;
            location.properties.order = 1;

            if (isValidLatLong(locationItem.latitude, true) && isValidLatLong(locationItem.longitude, false)) {
              location.properties.lat = convertLatitude(locationItem.latitude);
              location.properties.long = convertLongitude(locationItem.longitude);
            }
            location.properties.address = this.setAddressPopUpData(locationItem);
            location.properties.locationsNames = this.setStoragePopUpData(locationItem);

            storageFeaturesBySDT.features.push(location);
            let point = turf.center(location);
            point.properties = location.properties;
            allCenterPointsFeature.features.push(point);

          }
        }
      }
      return {
        'allFeatures': storageFeaturesBySDT,
        "allCenterPointsFeature": allCenterPointsFeature
      };
    },
    async getDataLocations() {
      let locationsFeaturesBySDT = {
        type: "FeatureCollection",
        features: []
      }
      let allCenterPointsFeature = {
        type: 'FeatureCollection',
        features: []
      };

      let location = null;
      let locationJson = null;
      const mergeFormat = await MapService.mergeLocationsFormats(this.locations);

      for (const locationItem of mergeFormat) {
        const sdt = _.find(this.stages, {
          storage_device_type_id: parseInt(locationItem.storage_device_type_id),
          checked: true,
        });
        if (
          this.IsJsonString(locationItem.location) &&
          locationItem.location &&
          locationItem.location !== "null" &&
          sdt
        ) {
          locationJson = JSON.parse(locationItem.location);
          if (
            locationJson &&
            locationJson.features &&
            locationJson.features.length > 0
          ) {
            location = locationJson.features[0];
            if(!location.properties){
              location.properties = {}
            }
            location.properties.id = locationItem.id;
            location.properties.gln = locationItem.gln;
            location.properties.locationName = locationItem.name;
            location.properties.telephone = locationItem.telephone;
            location.properties.email = locationItem.email;
            location.properties.area = locationItem.area;
            location.properties.order = 2;


            if (isValidLatLong(locationItem.latitude, true) && isValidLatLong(locationItem.longitude, false)) {
              location.properties.lat = convertLatitude(locationItem.latitude);
              location.properties.long = convertLongitude(locationItem.longitude);
            }
            location.properties.address = this.setAddressPopUpData(locationItem);
            location.properties.locationsNames = locationItem.name;

            locationsFeaturesBySDT.features.push(location);
            let point = turf.center(location);
            point.properties = location.properties;
            allCenterPointsFeature.features.push(point);
          }
        }
      }
      return {
        "allFeatures": locationsFeaturesBySDT,
        "allCenterPointsFeature": allCenterPointsFeature
      };

    },

    setAddressPopUpData(locationItem) {
      return this.checkIfExist(
          locationItem.address1,
          true,
          ', ',
        ) +
        this.checkIfExist(
          locationItem.city,
          true,
          ', ',
        ) +
        this.checkIfExist(
          locationItem.state,
          true,
          ', ',
        ) +
        this.checkIfExist(locationItem.country, false)
    },
    setStoragePopUpData(locationItem) {
      return this.checkIfExist(
        locationItem.primaryLocation.name,
          true,
          ' | ',
        )
        +
        this.checkIfExist(
          locationItem.name,
          false
        )
    },

    /**
     * @return {boolean}
     */
    IsJsonString(json) {
      try {
        JSON.parse(json);
      } catch (e) {
        return false;
      }
      return true;
    },
  },

  beforeDestroy() {
    this.$root.$off('on:recalculate')
    this.$root.$off('apply:dashboard-filters')
  }
}
</script>

<style scoped>
.v-chip {
  border-radius: .3em;
}
</style>
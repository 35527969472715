<template>
  <v-overlay opacity="1" v-if="isEmpty" absolute color="white" style="height: 100%">
    <div class="d-flex justify-center align-center fill-height">
      <v-chip small not-apply-styles>
        <v-icon small>mdi-alert-outline</v-icon>
        <p style="font-size: 12px; font-weight: normal" class="ma-0 pa-0 mx-4">{{ $t('general.ui.not_data_found') }}</p>
      </v-chip>
    </div>
  </v-overlay>
</template>

<script>
export default{
  name: "EmptyWidgetData",
  props: {
    isEmpty: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>
.v-chip {
  border-radius: .3em;
}
</style>
<template>
  <v-overlay opacity="1" v-if="loading" absolute color="white" style="height: 100%">
    <div class="d-flex justify-center align-center fill-height">
      <v-chip small not-apply-styles>
        <v-progress-circular indeterminate color="neuter" size="14" width="3" />
        <p style="font-size: 12px; font-weight: normal" class="ma-0 pa-0 mx-4">{{ $t('widgets.loadData') }}</p>
      </v-chip>
    </div>
  </v-overlay>
</template>

<script>
export default{
  name: "LoadingWidgetData",
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>
.v-chip {
  border-radius: .3em;
}
</style>
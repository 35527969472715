<template>
  <v-toolbar v-if="(!isEditingEnabled && hasActiveFilters) && !loading" class="pr-4 pl-2 toolbar__filters" dense>
    <div>
      <v-hover v-slot:default="{ hover }">
        <v-icon
          class="chip"
          color="#0f3146"
          left
          small
          @click="clearAllFilters"
        >
          {{ hover ? "mdi-filter-remove" : "mdi-filter" }}
        </v-icon>
      </v-hover>
      <v-chip
        v-for="(value, key) in filters"
        v-if="isFilterActive(key, value)"
        :key="key"
        active-class="inactive-chip"
        close
        @click:close="removeFilter(key)"
      >
        {{formatFilterValue(key, value) }}
      </v-chip>
    </div>
  </v-toolbar>
</template>

<script>
  export default {
    name: "FilterChipsToolbar",
    props: {
      filters: Object,
      layout: Object,
      hasActiveFilters: Boolean,
      isLocalStoragePresent: Boolean,
      isEditingEnabled: Boolean
    },
    data: () => ({
      loading: false
    }),
    mounted() {
      console.log('>>>> toolbar-filters')
      this.$emit('on:loading', true)
      this.loading = true;

      setTimeout(() => {
        let filters = null;

        if (this.isLocalStoragePresent) {
          const profile = JSON.parse(localStorage.getItem('profile'))
          filters = JSON.parse(localStorage.getItem(`filters-${profile.company_id}-global`))
          console.log('global >>>', filters)

          if (!filters) {
            filters = JSON.parse(localStorage.getItem(`filters-${profile.company_id}-${this.layout.id}`))
            console.log("local >>>", filters)
          }

          if (filters)
            Object.keys(this.filters).forEach(key => {
              if (key !== 'applyGlobally')
                this.filters[key] = filters[key];
            });
        }

        localStorage.removeItem('filters-null');
        this.$emit('on:loading', false)
        this.loading = false;
      }, 1000)
    },
    methods: {
      removeFilter(filterKey) {
        const profile = JSON.parse(localStorage.getItem('profile'))
        const layoutId = this.layout.id;
        const localeKey = !this.filters.isGlobal ? `filters-${profile.company_id}-${layoutId}` : `filters-${profile.company_id}-global`;

        if (Array.isArray(this.filters[filterKey])) {
          this.filters[filterKey] = [];
        } else {
          this.filters[filterKey] = null;
        }

        if (filterKey === 'classificationTypes') {
          this.filters.locationType = null;
          this.filters[filterKey] = [];
        }

        if (filterKey === 'secondaryLocations') {
          this.$root.$emit("set:location:origin", {
            locations: this.filters.primaryLocations,
            storageDevice: []
          }, false)
        }

        if (filterKey === 'primaryLocations') {
          this.filters.secondaryLocations = [];
          this.$root.$emit("set:location:origin", {
            locations: [],
            storageDevice: []
          }, false)
        }

        localStorage.setItem(localeKey, JSON.stringify(this.filters));
        this.$emit('filter-updated', this.filters);
        this.$root.$emit('on:recalculate')
        this.$root.$emit('apply:dashboard-filters');
      },
      clearAllFilters() {
        const profile = JSON.parse(localStorage.getItem('profile'))
        const layoutId = this.layout.id;
        let localeKey = layoutId ? `filters-${profile.company_id}-${layoutId}` : `filters-${profile.company_id}-global`;

        const globalFilters = JSON.parse(localStorage.getItem(`filters-${profile.company_id}-global`))
        if (globalFilters)
          localeKey = `filters-${profile.company_id}-global`;

        Object.keys(this.filters).forEach(key => {
          if (Array.isArray(this.filters[key])) {
            this.filters[key] = [];
          } else {
            this.filters[key] = null;
          }

          this.$root.$emit("set:location:origin", {
            locations: [],
            storageDevice: []
          })
        });

        localStorage.setItem(localeKey, JSON.stringify(this.filters));
        this.$root.$emit('on:recalculate')
        this.$root.$emit('apply:dashboard-filters');
      },
      isFilterActive(key, value) {
        if ((Array.isArray(value) || value !== null) && !['isGlobal', 'applyGlobally', 'locationType'].includes(key)) {
          return Array.isArray(value)? value.length > 0 : value !== null;
        }
        return false;
      },
      formatFilterValue(key, value) {
        const label = this.$t(`widgets.filters.${key}`);
        const formattedValue = Array.isArray(value) ? value.length : (value !== null ? 1 : "");
        return `${label}: ${formattedValue}`;
      }
    }
  }
</script>

<style scoped>
  .inactive-chip {
    background-color: transparent !important;
    cursor: default !important;
  }

  .v-chip {
    border-radius: .3em !important;
    font-weight: 500;
    color: #0f3146;
    background-color: rgba(3, 172, 140, 0.02) !important;
    border: 1px solid rgba(15, 49, 70, 0.2);
    margin: 0 5px 0 0;
  }

  .v-chip:hover {
    background-color: rgba(0, 241, 194, 0.11) !important;
    cursor: pointer;
  }

  .chip {
    padding: .45rem;
    border-radius: .3em !important;
    font-weight: 500;
    color: #0f3146;
    background-color: rgba(3, 172, 140, 0.02) !important;
    border: 1px solid rgba(15, 49, 70, 0.2);
    margin: 0 5px 0 0;
  }

  span :deep(.v-chip__content) {
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
  }

  :deep(.v-select.v-input--dense .v-chip) {
    border-radius: .3em !important;
  }

  .overlay-chip {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .2em .2em 0 0 !important;
  }
</style>

<template>
  <v-tooltip left content-class="tooltip__styles">
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="toData()" icon v-show="!isEditingEnabled" v-on="on" v-bind="attrs" :loading="loading" v-if="showAction">
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </template>
    <span>
      {{ $t('widgets.buttons.viewData') }}
    </span>
  </v-tooltip>
  <!--<v-btn @click="toData()" small text v-show="!isEditingEnabled" class="btn-container" color="grey darken-2">
    <span class="btn-text">{{ $t('widgets.buttons.viewData') }}</span>
    <v-icon class="btn-icon">mdi-arrow-right</v-icon>
  </v-btn>-->
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import sortList from "@/services/OrderListService"

const entityToRouteMap = {
  Record: 'records',
  Document: 'documents',
  Location: 'locations',
  StorageDevice: 'storage',
  Culture: 'products',
  Season: 'seasons',
  Input: 'inputs',
  Operator: 'operators',
  Equipment: 'equipments',
  Survey: 'inspections',
  Producer: 'producers'
};

export default {
  name: "ShowFilteredData",
  props: {
    config: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    showAction: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      structuresDetails: 'records/structures',
      process: 'authority/authoritiesProcess',
      areaRanges: 'ui/GET_LOCATION_RANGES',
      layout: 'dashboard/LAYOUT'
    }),
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    },
    stages() {
      return sortList.orderListByUppercase(
        this.$store.getters['storage_type/storageDeviceTypesActiveByCompany'],
        'storage_type_name'
      )
    },
    filters: {
      get() {
        return this.$store.getters['ui/GET_FILTERS']
      },
      set(val) {
        this.$store.dispatch('ui/SET_FILTERS', val)
      },
    },
    filtersObjects: {
      get() {
        return this.$store.getters['ui/GET_OBJECTS_TO_FILTERS']
      },
      set(val) {
        this.$store.dispatch('ui/SET_OBJECTS_TO_FILTERS', val)
      },
    },
    data: {
      get() {
        return this.$store.getters['ui/GET_DATA']
      },
      set(val) {
        this.$store.dispatch('ui/SET_DATA', val)
      },
    },
    structureFilters: {
      get() {
        return this.$store.getters['ui/GET_STRUCTURE_FILTERS']
      },
      set(val) {
        this.$store.dispatch('ui/SET_STRUCTURE_FILTERS', val)
      },
    },
    structuresByIds: {
      get() {
        return this.$store.getters['ui/GET_STRUCTURES_BY_IDS']
      },
      set(val) {
        this.$store.commit('ui/STRUCTURES_BY_IDS', val)
      },
    },
    producerFilters: {
      get() {
        return this.$store.getters["ui/GET_PRODUCER_FILTERS"]
      },
      set(value) {
        this.$store.commit("ui/PRODUCER_TO_FILTERS", value)
      },
    },
  },
  methods: {
    async toData() {
      this.loading = true;

      this.$store.commit('ui/CLEAR_LOCAL_FILTERS')
      let filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'))
      filtersRecords.filtersObjects.record.status = ['VALIDATED'];
      filtersRecords.type = 'records';
      let filtersStorages = JSON.parse(localStorage.getItem('filtersStorages'))
      filtersStorages.type = 'storages';
      let filtersProducer = JSON.parse(localStorage.getItem('filtersProducer'))
      filtersProducer.type = 'producer';
      let filtersDocuments = JSON.parse(localStorage.getItem('filtersDocuments'))
      filtersDocuments.type = 'documents';
      let filtersLocations = JSON.parse(localStorage.getItem('filtersLocations'))
      filtersLocations.type = 'locations';

      switch (this.item.subtype) {
        case 'COUNT':
          const entityType = this.config.filter.entity;
          const routeName = entityToRouteMap[entityType];

          if (entityType === 'StorageDevice') {
            filtersStorages.filtersObjects.storages.classificationTypes = this.config.filter.classificationTypes;
            filtersStorages.filtersObjects.storages.area = this.areaRanges.secondary;
            filtersStorages.filtersObjects.stringFilter = null;
            filtersStorages.filtersObjects.locations.status = ['VALIDATED'];
            filtersStorages.filtersObjects.locations.isActive = this.config.filter.active;
            // this.filtersObjects.storages.classificationTypes = this.config.filter.classificationType ? [this.config.filter.classificationType] : [];
            // this.filtersObjects.locations.status = ['VALIDATED'];
            // this.filtersObjects.stringFilter = null;
            // this.filtersObjects.locations.isActive = this.config.filter.active;
            localStorage.setItem('filtersStorages', JSON.stringify(filtersStorages));
          }

          if (['Producer', 'Document', 'Location'].includes(entityType)) {
            if (entityType === 'Producer') {
              filtersProducer.isActive = this.config.filter.active;
              localStorage.setItem('filtersProducer', JSON.stringify(filtersProducer));
            }
            if (entityType === 'Document') {
              filtersDocuments.filtersObjects.document.isActive = this.config.filter.active;
              this.filtersObjects.document.isActive = [this.config.filter.active];
              localStorage.setItem('filtersDocuments', JSON.stringify(filtersDocuments));
            }
            if (entityType === 'Location') {
              filtersLocations.filtersObjects.locations.isActive = this.config.filter.active;
              filtersLocations.filtersObjects.locations.classificationTypes = this.config.filter.classificationTypes;
              filtersLocations.filtersObjects.locations.status = ['VALIDATED'];
              filtersLocations.filtersObjects.locations.isActive = this.config.filter.active;
              filtersLocations.filtersObjects.locations.area = this.areaRanges.primary;
              // this.filtersObjects.locations.isActive = this.config.filter.active;
              // this.filtersObjects.locations.status = ['VALIDATED'];
              localStorage.setItem('filtersLocations', JSON.stringify(filtersLocations));
            }
          }

          await this.applyGlobalFilters({ entityType })

          if (!routeName) {
            console.info('No route defined for entity:', entityType);
            return;
          }

          this.loading = false;

          if (entityType !== 'Record')
            await this.$router.push({ name: routeName });

          break;
        case 'AGTRACE_SUM':
        case 'AGTRACE_SUM_BY_UNIT':
        case 'AGTRACE_COUNT_SELECTED_PRODUCTS':
          filtersRecords.filtersObjects.process_ids = [...this.config.filter.processIds];
          filtersRecords.filtersObjects.products_ids = [...this.config.filter.nestedValues];

          _.forEach(this.structuresByIds, (structure) => {
            this.$set(structure, 'structures_selected', this.data[structure.structure_id]);
          });

          filtersRecords.structures.structuresByIds = this.structuresByIds;

          localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords))

          this.loading = false;

          await this.applyGlobalFilters({ subtype: this.item.subtype });
          break;
        case 'ESTIMATE':
          await this.applyGlobalFilters({ subtype: this.item.subtype });
          break;
        case 'QUANTITY_PRODUCTS_BY_STATE_LOCATION':
        case 'QUANTITY_PRODUCTS_BY_LOCATION':
          filtersRecords.filtersObjects.process_ids = [...this.config.filter.processIds];
          filtersRecords.filtersObjects.products_ids = [...this.config.filter.nestedValues];

          localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords))

          await this.applyGlobalFilters({ subtype: this.item.subtype });
          break;
        case 'STRUCTURE_AVERAGE':
          filtersRecords.filtersObjects.process_ids = [...this.config.filter.processIds];
          this.structureFilters = _.filter(this.structuresDetails, structure => this.config.filter.structureIds.includes(structure.id));

          if (this.structureFilters && this.structureFilters.length > 0) {
            let ids = [];

            _.forEach(this.structureFilters, structure => {
              ids.push(structure.id)
            })

            const profile = JSON.parse(localStorage.getItem('profile'));
            await this.$store.dispatch('ui/SET_STRUCTURES_BY_IDS', [profile, ids, this.$toast])
          } else {
            this.structuresByIds = []
          }
          console.log(this.structureFilters)

          this.structuresByIds = [...this.structuresByIds.map(s => ({
            ...s,
            structures_selected: s.structure_values
          }))]
          console.log(this.structuresByIds)

          _.forEach(
            this.structuresByIds,
            structure => {
              this.$set(
                this.data,
                structure.structure_id,
                structure.structures_selected
              )
            }
          )
          console.log(this.data)
          
          _.forEach(this.structuresByIds, (structure) => {
            this.$set(structure, 'structures_selected', this.data[structure.structure_id]);
          });

          filtersRecords.structures.structuresByIds = [...this.structuresByIds];
          filtersRecords.structures.structureFilter = [...this.structureFilters];

          localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords))

          this.loading = false;

          await this.applyGlobalFilters({ subtype: this.item.subtype });
          break;
        case 'COUNT_PROCESS_BY_LOCATION':
          filtersRecords.filtersObjects.process_ids = [...this.config.filter.processIds];

          _.forEach(this.structuresByIds, (structure) => {
            this.$set(structure, 'structures_selected', this.data[structure.structure_id]);
          });

          filtersRecords.structures.structuresByIds = this.structuresByIds;

          localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords))

          this.loading = false;

          await this.applyGlobalFilters({ subtype: this.item.subtype });
          break;
        case 'TOP_TRACEABILITY_QR_VISIT':
          this.loading = false;
          await this.$router.push({ name: 'traceability-view' });
          break;
        case 'LOCATIONS_BY_STATE':
          await this.applyGlobalFilters({ subtype: this.item.subtype });
        default:
          console.info('No route defined for card type:', `${this.item.type}: ${this.item.subtype}`);
      }
    },
    async applyGlobalFilters(props) {
      const profile = JSON.parse(localStorage.getItem('profile'))
      let filters = JSON.parse(localStorage.getItem(`filters-${profile.company_id}-${this.layout.id}`));
      if (!filters) {
        filters = JSON.parse(localStorage.getItem(`filters-${profile.company_id}-global`));
      }

      console.log(filters)

      switch(this.item.subtype) {
        case "COUNT":
          await this.replaceGlobalFiltersForWidgetCount(props.entityType, filters);
          break;
        case 'AGTRACE_SUM':
        case 'AGTRACE_SUM_BY_UNIT':
        case 'AGTRACE_COUNT_SELECTED_PRODUCTS':
          await this.replaceGlobalFiltersForWidgetSum(props.subtype, filters)
          break;
        case 'STRUCTURE_AVERAGE':
          await this.replaceGlobalFiltersForWidgetAverage(props.subtype, filters)
          break;
        case 'ESTIMATE':
          await this.replaceGlobalFiltersForWidgetEstimate(props.subtype, filters)
          break;
        case 'COUNT_PROCESS_BY_LOCATION':
          await this.replaceGlobalFiltersForWidgetCountPorcessByLocation(props.subtype, filters)
          break;
        case 'LOCATIONS_BY_STATE':
          await this.replaceGlobalFiltersForWidgetLocationByState(props.subtype, filters)
          break;
        case 'QUANTITY_PRODUCTS_BY_STATE_LOCATION':
          await this.replaceGlobalFiltersForWidgetProductsByState(props.subtype, filters)
          break;
        case 'QUANTITY_PRODUCTS_BY_LOCATION':
          await this.replaceGlobalFiltersForWidgetProductsByLocation(props.subtype, filters)
          break;
      }
    },
    async replaceGlobalFiltersForWidgetProductsByLocation(subtype, filters) {
      let filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'))

      // DATA DO PROCESO
      if (filters.dates && filters.dates.length > 0) {
        filtersRecords.filtersRecordDate = filters.dates
        filtersRecords.filtersDate = `${filters.dates[0]} ~ ${filters.dates[1]}`
      }

      if (filters.processes.length > 0)
        filtersRecords.filtersObjects.process_ids = filters.processes;

      if (filters.secondaryLocations && filters.secondaryLocations.length > 0) {
        filtersRecords.origin_locations_filters = filters.primaryLocations;
        filtersRecords.filtersObjects.sdi_origin_ids = filters.secondaryLocations;
        filtersRecords.destination_locations_filters = filters.primaryLocations;
        filtersRecords.filtersObjects.sdi_destination_ids = filters.secondaryLocations;
      }

      localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords))

      await this.redirectTo('records', filtersRecords.filtersObjects.process_ids)
    },
    async replaceGlobalFiltersForWidgetProductsByState(subtype, filters) {
      let filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'))

      // DATA DO PROCESO
      if (filters.dates && filters.dates.length > 0) {
        filtersRecords.filtersRecordDate = filters.dates
        filtersRecords.filtersDate = `${filters.dates[0]} ~ ${filters.dates[1]}`
      }

      if (filters.processes.length > 0)
        filtersRecords.filtersObjects.process_ids = filters.processes;

      if (filters.secondaryLocations && filters.secondaryLocations.length > 0) {
        filtersRecords.origin_locations_filters = filters.primaryLocations;
        filtersRecords.filtersObjects.sdi_origin_ids = filters.secondaryLocations;
        filtersRecords.destination_locations_filters = filters.primaryLocations;
        filtersRecords.filtersObjects.sdi_destination_ids = filters.secondaryLocations;
      }

      localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords))

      await this.redirectTo('records', filtersRecords.filtersObjects.process_ids)
    },
    async replaceGlobalFiltersForWidgetLocationByState(subtype, filters) {
      if(this.config.filter.locationType === 'PRIMARY') {
        let filtersLocations = JSON.parse(localStorage.getItem('filtersLocations'));

        filtersLocations.filtersObjects.locations.ids = filters.primaryLocations;
        localStorage.setItem('filtersLocations', JSON.stringify(filtersLocations));
        
        await this.$router.push({ name: 'locations' });
      }

      if(this.config.filter.locationType === 'SECONDARY') {
        let filtersStorages = JSON.parse(localStorage.getItem('filtersStorages'));

        filtersStorages.filtersObjects.storages.ids = filters.secondaryLocations;
        filtersStorages.filtersObjects.storages.primaryLocationIds = filters.primaryLocations;

        localStorage.setItem('filtersStorages', JSON.stringify(filtersStorages));
        
        await this.$router.push({ name: 'storage' });
      }
      
    },
    async replaceGlobalFiltersForWidgetCountPorcessByLocation(subtype, filters) {
      let filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'))

      // DATA DO PROCESO
      if (filters.dates && filters.dates.length > 0) {
        filtersRecords.filtersRecordDate = filters.dates
        filtersRecords.filtersDate = `${filters.dates[0]} ~ ${filters.dates[1]}`
      }

      if (filters.processes.length > 0)
        filtersRecords.filtersObjects.process_ids = filters.processes;

      if (filters.secondaryLocations && filters.secondaryLocations.length > 0) {
        filtersRecords.origin_locations_filters = filters.primaryLocations;
        filtersRecords.filtersObjects.sdi_origin_ids = filters.secondaryLocations;
        filtersRecords.destination_locations_filters = filters.primaryLocations;
        filtersRecords.filtersObjects.sdi_destination_ids = filters.secondaryLocations;
      }

      localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords))

      await this.redirectTo('records', filtersRecords.filtersObjects.process_ids)
    },
    async replaceGlobalFiltersForWidgetEstimate(subtype, filters) {
      let filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'))

      // DATA DO PROCESO
      if (filters.dates && filters.dates.length > 0) {
        filtersRecords.filtersRecordDate = filters.dates
        filtersRecords.filtersDate = `${filters.dates[0]} ~ ${filters.dates[1]}`
      }

      if (filters.processes.length > 0)
        filtersRecords.filtersObjects.process_ids = filters.processes;

      if (filters.secondaryLocations && filters.secondaryLocations.length > 0) {
        filtersRecords.origin_locations_filters = filters.primaryLocations;
        filtersRecords.filtersObjects.sdi_origin_ids = filters.secondaryLocations;
        filtersRecords.destination_locations_filters = filters.primaryLocations;
        filtersRecords.filtersObjects.sdi_destination_ids = filters.secondaryLocations;
      }

      localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords))

      await this.redirectTo('records', filtersRecords.filtersObjects.process_ids)
    },
    async replaceGlobalFiltersForWidgetAverage(subtype, filters) {
      let filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'))

      // DATA DO PROCESO
      if (filters.dates && filters.dates.length > 0) {
        filtersRecords.filtersRecordDate = filters.dates
        filtersRecords.filtersDate = `${filters.dates[0]} ~ ${filters.dates[1]}`
      }

      if (filters.processes.length > 0)
        filtersRecords.filtersObjects.process_ids = filters.processes;

      if (filters.secondaryLocations && filters.secondaryLocations.length > 0) {
        filtersRecords.origin_locations_filters = filters.primaryLocations;
        filtersRecords.filtersObjects.sdi_origin_ids = filters.secondaryLocations;
        filtersRecords.destination_locations_filters = filters.primaryLocations;
        filtersRecords.filtersObjects.sdi_destination_ids = filters.secondaryLocations;
      }

      localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords))

      await this.redirectTo('records', filtersRecords.filtersObjects.process_ids)
    },
    async replaceGlobalFiltersForWidgetSum(subtype, filters) {
      let filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'))

      // DATA DO PROCESO
      if (filters.dates && filters.dates.length > 0) {
        filtersRecords.filtersRecordDate = filters.dates
        filtersRecords.filtersDate = `${filters.dates[0]} ~ ${filters.dates[1]}`
      }

      if (filters.processes.length > 0)
        filtersRecords.filtersObjects.process_ids = filters.processes;

      if (filters.secondaryLocations && filters.secondaryLocations.length > 0) {
        filtersRecords.origin_locations_filters = filters.primaryLocations;
        filtersRecords.filtersObjects.sdi_origin_ids = filters.secondaryLocations;
        filtersRecords.destination_locations_filters = filters.primaryLocations;
        filtersRecords.filtersObjects.sdi_destination_ids = filters.secondaryLocations;
      }

      localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords))

      await this.redirectTo('records', filtersRecords.filtersObjects.process_ids)
    },
    async replaceGlobalFiltersForWidgetCount(entityType, filters) {
      if (entityType === 'Record') {
        let filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'))

        // DATA DO PROCESO
        if (filters.dates && filters.dates.length > 0) {
          filtersRecords.filtersRecordDate = filters.dates
          filtersRecords.filtersDate = `${filters.dates[0]} ~ ${filters.dates[1]}`
        }
        
        // DATA DO REGISTRO
        if (filters.recordDates && filters.recordDates.length > 0) {
          filtersRecords.filtersRecordRegisterDate = filters.recordDates
          filtersRecords.filtersRegisterDate = `${filters.recordDates[0]} ~ ${filters.recordDates[1]}`
        }

        if (filters.processes.length > 0)
          filtersRecords.filtersObjects.process_ids = filters.processes;

        if (filters.secondaryLocations && filters.secondaryLocations.length > 0) {
          filtersRecords.origin_locations_filters = filters.primaryLocations;
          filtersRecords.filtersObjects.sdi_origin_ids = filters.secondaryLocations;
          filtersRecords.destination_locations_filters = filters.primaryLocations;
          filtersRecords.filtersObjects.sdi_destination_ids = filters.secondaryLocations;
        }

        localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords))

        await this.redirectTo('records', filtersRecords.filtersObjects.process_ids, filters)
      } else if (entityType === 'Document') {
        let filtersDocuments = JSON.parse(localStorage.getItem('filtersDocuments'))
        if (filters.recordDates && filters.recordDates.length > 0) {
          filtersDocuments.filtersDocumentDate = {
            document_date: filters.recordDates,
            expiration_date: []
          };
        }

        if (filters.secondaryLocations && filters.secondaryLocations.length > 0) {
          filtersDocuments.destination_locations_filters = filters.primaryLocations;
          filtersDocuments.storage_devices_ids = filters.secondaryLocations;
          filtersDocuments.filtersObjects.sdi_destination_ids = filters.secondaryLocations;
        }

        localStorage.setItem('filtersDocuments', JSON.stringify(filtersDocuments))
      } else if (entityType === 'Survey') {
        let filtersInspections = JSON.parse(localStorage.getItem('filtersInspections'))

        if (filters.recordDates && filters.recordDates.length > 0) {
          filtersInspections.filtersInspectionsDate = filters.recordDates
          filtersInspections.record_date_time = false
          filtersInspections.filtersDate = `${filters.recordDates[0]} ~ ${filters.recordDates[1]}`
        }

        if (filters.secondaryLocations && filters.secondaryLocations.length > 0) {
          filtersInspections.origin_locations_filters = filters.primaryLocations;
          filtersInspections.filtersObjects.sdi_origin_ids = filters.secondaryLocations;
          filtersInspections.destination_locations_filters = filters.primaryLocations;
          filtersInspections.filtersObjects.sdi_destination_ids = filters.secondaryLocations;
        }

        localStorage.setItem('filtersInspections', JSON.stringify(filtersInspections))
      } else if (entityType === 'Location') {
        let filtersLocations = JSON.parse(localStorage.getItem('filtersLocations'))

        filtersLocations.filtersObjects.locations.ids = filters.primaryLocations;

        if (filters.stages && filters.stages.length > 0) {
          filtersLocations.filtersObjects.storage_device_types_ids = filters.stages.map(id =>
            this.stages.find(stage => stage.storage_device_type_id === id)
          ).filter(stage => stage);
        }

        if (filters.locationType && filters.locationType === "PRIMARY") {
          filtersLocations.filtersObjects.locations.classificationTypes = filters.classificationTypes;
        }

        localStorage.setItem('filtersLocations', JSON.stringify(filtersLocations))
      } else if (entityType === 'StorageDevice') {
        let filtersStorages = JSON.parse(localStorage.getItem('filtersStorages'))

        filtersStorages.filtersObjects.storages.primaryLocationIds = filters.primaryLocations;
        filtersStorages.filtersObjects.storages.ids = filters.secondaryLocations;

        if (filters.stages && filters.stages.length > 0) {
          filtersStorages.filtersObjects.storage_device_types_ids = filters.stages.map(id =>
            this.stages.find(stage => stage.storage_device_type_id === id)
          ).filter(stage => stage);
        }

        if (filters.locationType && filters.locationType === "SECONDARY") {
          filtersStorages.filtersObjects.storages.classificationTypes = filters.classificationTypes;
        }

        localStorage.setItem('filtersStorages', JSON.stringify(filtersStorages))
      } else if (entityType === 'Producer') {
        let filtersProducer = JSON.parse(localStorage.getItem('filtersProducer'))
        filtersProducer.locations = [...filters.primaryLocations];
        filtersProducer.storageDevices = [...filters.secondaryLocations];

        localStorage.setItem('filtersProducer', JSON.stringify(filtersProducer))
      }
    },
    async redirectTo(route, processIds = [], filters = null) {
      console.log('processIds:', processIds)
      if (route === 'records') {
        const filteredProcesses = this.process.filter(p => {
          if (processIds.length > 0) {
            return processIds.includes(p.id)
          } else
            if (this.config.filter.processIds && this.config.filter.processIds.length > 0)
              return this.config.filter.processIds.includes(p.id)
          }
        );

        const sortedProcesses = filteredProcesses.sort((a, b) => {
          if (a.process_name && b.process_name) {
            return a.process_name.localeCompare(b.process_name);
          }
          if (a.process_name) return -1;
          if (b.process_name) return 1;
          return 0;
        });

        if (filters && filters.stages.length > 0) {
          console.log(filters.stages[0])
          await this.$router.push({ name: 'records', params: { stage: filters.stages[0] } });
        } else if (sortedProcesses.length > 0) {
          const process = sortedProcesses[0];
          await this.$router.push({ name: 'records', params: { stage: process.sdt_to_show.id } });
        } else {
          await this.$router.push({ name: 'records' });
        }
      }
    }
  }
}
</script>

<style scoped>
.btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  z-index: 999;
  background-color: transparent;
  transition: background-color 0s linear;
}

.btn-text {
  visibility: hidden;
  width: 0;
  opacity: 0;
  transition: visibility 0s, width 0s, opacity 0.5s linear;
  transition: margin-right 0.5s ease-out, width 0.3s ease-out, opacity 0.3s ease-out;
}

.btn-icon {
  transition: transform 0.2s ease-out;
}

.btn-container:hover {
  background-color: #e8e8e8;
}

.btn-container:hover .btn-text {
  visibility: visible;
  width: auto;
  opacity: 1;
  margin-right: 0;
}

.btn-container:hover .btn-icon {
  transform: translateX(5px);
}

.tooltip__styles {
  background: #2e2e2e;
  font-weight: bold;
  color: rgb(239, 239, 239);
}
</style>
